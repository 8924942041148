import Flickity from "flickity";

window.initializeEventReminderCarousel = () => {
  const carousels = document.querySelectorAll(".event-reminders-carousel");

  carousels.forEach(carousel => {
    const options = {
      freeScroll: true,
      pageDots: true,
      contain: true,
      cellAlign: "left",
      groupCells: true,
      imagesLoaded: true,
      prevNextButtons: true,
      cellSelector: ".reminder-carousel-cell",
      arrowShape:
        "M2.896 52.164A4.716 4.716 0 0 1 .6 48a4.716 4.716 0 0 1 2.292-4.164l41.82-42.464a4.572 4.572 0 0 1 6.536 0c1.8 1.832 1.8 4.804 0 6.636L11.86 48l39.388 39.992c1.8 1.832 1.8 4.8 0 6.636a4.572 4.572 0 0 1-6.536 0L2.896 52.16v.004z",
      on: {
        dragStart() {
          document.ontouchmove = e => {
            e.preventDefault();
          };
        },

        dragEnd() {
          document.ontouchmove = () => {
            return true;
          };
        }
      }
    };

    const flkty = new Flickity(carousel, options);

    flkty.on("resize", () => {
      const isSingleSlide = flkty.slides.length < 2;
      carousel.classList.toggle("is-single-slide", isSingleSlide);
    });
  });
};

document.addEventListener("turbo:frame-load", event => {
  const eventReminderFrame = event.target;

  if (eventReminderFrame.id === "events-carousel-block") {
    window.initializeEventReminderCarousel();
  }
});
